<template>
  <div>
    <CCard bodyWrapper style="min-height: 600px">
      <CContainer class="user-details-wrapper" v-if="user_data">
        <CContainer>
          <CWidgetDropdown color="transparent">
            <CDropdown class="float-right">
              <template #toggler-content>
                <CIcon name="cil-settings" />
              </template>
              <CDropdownItem @click="userSelect">Edit</CDropdownItem>
              <CDropdownItem @click="passwordResetModal = true"
                >Change Password</CDropdownItem
              >
              <CDropdownItem>Delete</CDropdownItem>
            </CDropdown>
          </CWidgetDropdown>
        </CContainer>
        <CRow style="margin-top: -25px">
          <CCol col="12">
            <CRow class="profile-header-wrapper">
              <CImg
                v-if="user_data.profilePicture"
                :src="user_data.profilePicture"
                width="120px"
                height="120px"
              />
              <span class="profile-header">
                <div class="username">{{ user_data.username }}</div>
                <div>{{ user_data.lastSeen }}</div>
                <div>{{ user_data.availability }}</div>
                <div>{{ user_data._id }}</div>
              </span>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="4"> User Type </CCol>
          <CCol col="8"> {{ user_data.roles[0].name }} </CCol>
        </CRow>
        <CRow>
          <CCol col="4"> Email </CCol>
          <CCol col="8"> {{ user_data.email }} </CCol>
        </CRow>
        <CRow>
          <CCol col="4"> Phone </CCol>
          <CCol col="8"> {{ user_data.phone }} </CCol>
        </CRow>
        <CRow>
          <CCol col="4"> Job Title </CCol>
          <CCol col="8"> {{ user_data.jobTitle }} </CCol>
        </CRow>
        <CRow>
          <CCol col="4"> Status </CCol>
          <CCol col="8"> {{ user_data.status }} </CCol>
        </CRow>
        <CRow>
          <CCol col="4"> Created</CCol>
          <CCol col="8"> {{ user_data.createdAt }} </CCol>
        </CRow>
        <CRow>
          <CCol col="4"> Updated</CCol>
          <CCol col="8"> {{ user_data.updatedAt }} </CCol>
        </CRow>
      </CContainer>
      <div>
        <CModal
          title="Change Password"
          color="#3c4b64"
          :centered="true"
          :show.sync="passwordResetModal"
        >
          <CRow>
            <CCol sm="12">
              <CInput
                type="password"
                label="New Password"
                placeholder="Enter new password here!"
                v-model="passwordReset.password"
                invalid-feedback="Please provide at least 7 characters."
                :is-valid="passwordValidator"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <CInput
                type="password"
                label="Confirm your Password"
                placeholder="Enter new password here!"
                v-model="passwordReset.confirm_password"
                invalid-feedback="Passwords are not matching!."
                :is-valid="passwordResetValidator"
              />
            </CCol>
          </CRow>
          <template #footer>
            <CButton @click="passwordResetCancel" color="danger"
              >Cancel</CButton
            >
            <CButton @click="passwordResetMethod" color="success"
              >Change</CButton
            >
          </template>
        </CModal>
      </div>
    </CCard>
    <div
      style="
        position: absolute;
        bottom: 45px;
        right: 15px;
        z-index: 9999;
        width: 50%;
      "
    >
      <CAlert color="success" :show.sync="currentAlertCounter" closeButton>
        Password reseted successfully
        <CProgress
          :max="3"
          :value="currentAlertCounter"
          height="3px"
          color="success"
          animate
        />
      </CAlert>
    </div>
  </div>
</template>

<script>
import UserServiceApi from "../../services/api/users";
import passwordResetModel from "../../models/passwordReset";
export default {
  name: "AddUser",
  data() {
    return {
      content: null,
      user_data: null,
      params: this.$route.params.id,
      passwordResetModal: false,
      passwordReset: new passwordResetModel(),
      currentAlertCounter: 0,
    };
  },
  mounted() {
    UserServiceApi.getUserDetails(this.params).then(
      (response) => {
        this.user_data = response.data.message;
        console.log(this.user_data);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    passwordResetMethod: function () {
      if (
        this.passwordReset.password &&
        this.passwordReset.confirm_password &&
        this.passwordReset.password.length >= 7 &&
        this.passwordReset.password === this.passwordReset.confirm_password
      ) {
        if (this.user_data._id) {
          UserServiceApi.passwordReset(
            this.user_data._id,
            this.passwordReset.password
          ).then(
            (response) => {
              this.passwordResetModal = false;
              this.currentAlertCounter = 3;
              this.passwordReset = new passwordResetModel();
            },
            (error) => {
              this.content =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
      }
    },

    userSelect() {
      this.$store.dispatch("user/selectUser", this.user_data).then(() => {
        this.$router.push({ name: "editUser" });
      });
    },
    passwordValidator(val) {
      return val ? val.length >= 7 : false;
    },
    passwordResetValidator(val) {
      if (this.passwordReset.password && this.passwordReset.confirm_password) {
        return (
          this.passwordReset.password === this.passwordReset.confirm_password
        );
      } else {
        true;
      }
    },
    passwordResetCancel() {
      this.passwordResetModal = false;
      this.passwordReset = new passwordResetModel();
    },
  },
};
</script>

<style scoped>
.profile-header {
  margin-top: 10px;
  padding-left: 20px;
  padding-top: 25px;
}

.username {
  font-size: 16px;
  font-weight: 700;
}

.profile-header .profile-header div {
  margin-bottom: 5px 0;
}

.profile-header-wrapper {
  border-radius: 30px;
  padding: 10px;
}
</style>
