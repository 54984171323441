import axios from 'axios'
import authHeader from '../auth/auth-header'


const API_URL = 'https://development.space-meets.com/api/'

class UserServiceApi {

  getAllUsers(page = 1, limit = 10, sort_values = { asc: true, column: null }) {
    var sort_value = 1;
    if (sort_values.asc) {
      sort_value = -1;
    }

    return axios.get(API_URL + 'auth/getallusers', {
      params: {
        page: page,
        limit: limit,
        sort: sort_values.column,
        sortValue: sort_value
      },
      headers: authHeader()
    });
  }

  getUserDetails(user_id) {
    return axios.get(API_URL + `auth/getUserDetails/${user_id}`, {
      headers: authHeader()
    });
  }

  passwordReset(user_id, password) {
    return axios.post(API_URL + `auth/resetpassword/${user_id}`, {
      password: password,
      headers: authHeader()
    });
  }

  searchUser(text, page = 1, sort_values = { asc: true, column: null }) {

    var sort_value = 1;
    if (sort_values.asc) {
      sort_value = -1;
    }

    return axios.get(API_URL + `auth/searchUsers/${text}`, {
      params: {
        page: page,
        sort: sort_values.column,
        sortValue: sort_value,

      },
      headers: authHeader()
    });
  }

  registerAdmin(data) {

    return axios.post(API_URL + 'auth/signupAdmin', data, {
      headers: authHeader()
    });
  }
}

export default new UserServiceApi()